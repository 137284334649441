@import "../../const/settings.scss";
@mixin colorTextStyle {
  color: var(--Color-Bg-Navy, #2a3467);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  // line-height: 150%
}
.wp-contentItem {
  cursor: pointer;
  .content {
    margin: auto;
    width: auto;
    border-radius: 16px;
    margin-bottom: 20px;
    background: var(--Bg, #f9f9f9);
    border: none;
    .left-content {
      display: flex;
      flex-direction: column;
    }
    .wp-image {
      display: flex;
      margin-bottom: 16px;
    }
    .wp-statistical {
      span {
        font-size: 14px;
      }
    }
  }
  .ant-card-body {
    padding: 16px !important;
  }

  .space-content {
    // margin-left: 24px;
  }
  .wp-case {
    margin-top: 12px;
    display: flex;
    gap: 8px;
    .case {
      display: flex;
      padding: 4px 8px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 1px solid var(--Color-Bg-Orange, #ddd);
      background: var(--Color-White, #fff);
    }
  }
  .see-detail {
    margin-top: 22px;
    height: 35px;
    width: 115px;
    border-radius: 5px;
    border: 1px solid var(--Color-2, #00c5d7) !important;
    background: var(--Color-3, #fff);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 0;
    span {
      color: var(--Color-4, #363636);
      font-size: 16px;
      font-style: italic;
      font-weight: 400;
      line-height: 150%; /* 21px */
      text-transform: capitalize;
    }
    @include media(md) {
      height: 30px !important;
      span {
        font-size: 14px !important;
      }
    }
  }
  .ant-divider-vertical {
    border: 1px solid #ddd;
    height: 15px;
  }

  .timeBracess {
    background: rgba(42, 52, 103, 0.54);
    border: none;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    color: var(--Color-White, #fff);
    display: flex;
    width: 56px;
    // padding: 4px 16px;
    padding: 2px 0;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .ratingPoint {
    display: flex;
    .point {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #ffa800;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      padding: 3px 6px;
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      border: 1px solid #ffa800;
    }
    .iconRatingStar {
      margin-left: 10px;
      width: 22px;
      height: 22px;
    }
  }
  .titleContent {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    color: var(--Color-Primary, #2a3467);
    text-overflow: ellipsis;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    // line-height: 150%;
    margin-bottom: 7px !important;
    line-height: 1.6;
  }
  .nameShowcase {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    color: var(--Color-Primary, #2a3467);
    text-overflow: ellipsis;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    // line-height: 150%;
    margin-bottom: 4px !important;
    text-align: left;
    line-height: 1.6;
  }
  .category {
    overflow: hidden;
    display: -webkit-box;
    color: var(--Color-Primary, #2a3467);
    font-style: normal;
    margin-bottom: 4px !important;
  }
  .btnDetail {
    margin-top: 5px;
    border-radius: 5px;
    background: white;
    border: 1px solid var(--color-brand-color, #00c5d7);
    color: var(--color-brand-color, #00c5d7);
    &:hover {
      background: var(--color-brand-color, #00c5d7);
      color: white;
    }
  }
  .description {
    overflow: hidden;
    color: var(--Color-Text, #6884a7);
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    margin-bottom: 4px;
  }
  .ratingRank {
    color: #ffa800;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    text-transform: capitalize;
  }
  .service {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--Color-Bg-Orange, #ddd);
    background: var(--Color-White, #fff);
    @include colorTextStyle;
  }
  .address {
    cursor: pointer;

    .addressText {
      width: fit-content;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @include colorTextStyle;
      font-size: 14px;
    }
  }
  .addressLogined {
    &:hover {
      .addressText {
        color: #00c5d7;
      }
    }
  }
  .view {
    @include colorTextStyle;
    color: var(--Color-Text, #6884a7);
  }
  .comments {
    @include colorTextStyle;
    color: var(--Color-Text, #6884a7);
  }
  .date {
    @include colorTextStyle;
    color: var(--Color-Text, #6884a7);
  }

  .mb-7 {
    margin-bottom: 7px;
  }

  .processImg {
    position: relative !important;
    border-radius: 8px;
    object-fit: cover;
    aspect-ratio: 1/1;
  }
  .even-clum {
    // height: auto;
    flex: 1;
    position: relative;
    // padding-top: 50%;
    @include media(mld_991) {
      width: 50%;
      .processImg {
        width: 100% !important;
        height: 160px !important;
      }
    }
    @include media(sm) {
      .processImg {
        height: calc(50vw - 41px) !important;
      }
    }
  }
}
.diary-home-skeleton{
  width: 100% !important;
  border-radius: 16px;
  height: 100px !important;
}
.wp-content-diary-home {
  .content {
    margin-bottom: 0px !important;
    .cusRow {
      text-align: left;
      .ant-col:first-of-type {
        aspect-ratio: 18 / 9;
      }
      .space-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      @media (min-width: 1200px) {
        .processImg {
          width: 170px !important;
          height: 170px !important;
        }
        .ant-col {
          max-width: 100%;
          flex: auto;
        }
        .space-content {
          margin-top: 8px;
        }
        .timeBracess {
          bottom: 6px;
        }
      }
      @media (max-width: 1200px) {
        .ant-col {
          flex: 1;
          margin-left: 8px;
        }
        .processImg {
          width: 100% !important;
          height: 100% !important;
        }
        .timeBracess {
          bottom: 0;
        }
      }
      @media (max-width: 1072px) {
        .ant-col {
          max-width: 100%;
          flex: auto;
          .space-content {
            margin-top: 8px;
          }
        }
      }
    }
  }
  .swiper-pagination {
    margin-top: 0 !important;
  }
  width: 100%;
  .swiper-pagination {
    margin-top: -12px;
  }
}
.wp-showcase-home {
  margin-top: 40px !important;
  .see-more-button {
    display: flex;
    width: auto !important;
    height: 35px !important;
    padding: 12px 20px !important;
    flex-shrink: 0;
    border-radius: 5px !important;
    background: var(--Color-1, #2a3467) !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    align-items: center;
    border: none !important;
    margin-top: 30px;
    span {
      margin-right: 5px;
      color: var(--Color-3, #fff);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    @include media(md) {
      span {
        font-size: 14px !important;
      }
      height: 30px !important;
    }
  }
}
.wp-perview {
  .logo-clinic {
    width: 25px !important;
    height: 25px !important;
    position: relative !important;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 50%;
  }
}

@include media(mld_991) {
  .content {
    .space-content {
      margin-left: 0px !important;
    }
    .titleContent {
      margin-top: 10px;
    }
  }
}
@include media(md) {
  .wp-content-diary-home {
    .ant-card-body {
      padding: 15px !important;
    }
  }
}
